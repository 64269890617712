import React, { FC } from 'react'

interface MemberProps {
    name: string,
    perex: string,
    description: string,
    imageSrc: string
}

const Member: FC<MemberProps> = ({
    name,
    perex,
    description,
    imageSrc
}) => {
    return (
        <div className="memberContainer">
            <div style={{position: "relative", display: "flex"}}>
                <img src={ imageSrc } alt={ name } style={{maxWidth: "100%"}}/>

                <div className="member">
                    <h3>{ name }</h3>

                    { perex }
                </div>
            </div>

            <p style={{padding: "1em"}}>
                { description }
            </p>
        </div>
    )
}

export default Member
