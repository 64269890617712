import React, {CSSProperties, FC} from 'react'

interface OfferProps {
    iconSrc: string,
    title: string,
    text: string,
    align: string,
    style?: CSSProperties
}

const Offer: FC<OfferProps> = ({
    iconSrc,
    title,
    text,
    align,
    style
}) => {
    return (
        <div className="offer" style={ style }>

            <img src={ iconSrc } alt={ title } className="icon" />

            <div>
                <h3>{ title }</h3>
                <p>{ text }</p>
            </div>

        </div>
    )
}

export default Offer
