import React, {FC} from 'react'

interface ReferenceProps {
    text: string,
    author: string,
}

const Reference: FC<ReferenceProps> = ({
   text,
   author,
}) => {
    return (
        <div className="references__item">
            <div style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center"
            }}>
                <p style={{alignSelf: "center"}}>{ text }</p>
            </div>

            <p className="author">
                { author }
            </p>
        </div>
    )
}

export default Reference
