import React, {useEffect, useState} from 'react';
import './App.css';
import {FiMenu} from "react-icons/fi";
import {NavLink, Route, BrowserRouter as Router, Routes} from "react-router-dom";
import {BannerLayer, Parallax, ParallaxBanner, ParallaxProvider} from "react-scroll-parallax";
import Member from "./components/Member"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareFacebook, faSquareInstagram, faSquareYoutube} from '@fortawesome/free-brands-svg-icons'
import Countdown from "react-countdown";
import Reference from "./components/Reference";
import Offer from "./components/Offer";

const Menu: React.FC = () => {
    const [scroll, setScroll] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 10);
        });
    })

    return (
        <header className={`headerMain ${scroll ? "sticky" : ""}`}>
            <div className="menu-icon" onClick={toggleMenu}>
                <FiMenu/>
            </div>

            <div className="headerContent">
                <div className="logo-container">
                    <img src={process.env.PUBLIC_URL + '/assets/logo.png'} alt="Laky Mari logo"/>
                </div>

                <nav className={`main-menu ${menuOpen ? 'open' : ''}`}>
                    <NavLink to="/" onClick={() => setMenuOpen(false)}>Úvod</NavLink>
                    <NavLink to="/o-nas" onClick={() => setMenuOpen(false)}>O nás</NavLink>
                    <NavLink to="/koncerty" onClick={() => setMenuOpen(false)}>Koncerty</NavLink>
                    <NavLink to="/pro-poradatele" onClick={() => setMenuOpen(false)}>Pro pořadatele</NavLink>
                    <NavLink to="/kontakt" onClick={() => setMenuOpen(false)}>Kontakt</NavLink>
                </nav>
            </div>
        </header>
    )
}

interface RendererParams {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
}

const renderer = ({days, hours, minutes, seconds, completed}: RendererParams) => {
    if (completed) {
        return ''
    } else {
        return (
            <div style={{
                width: "fit-content",
                borderRadius: ".3em",
                padding: "2em",
                margin: "600px auto 0",
                background: "#000000BB",
                color: "#FFF"
            }}>
                <div style={{fontSize: "1.5em"}}>Do premiéry klipu HOLUBIČKA zbývá</div>
                <div style={{display: "flex", flexDirection: "row", margin: "0 auto", justifyContent: "center"}}>
                    <div style={{margin: "0 1em", minWidth: "4em", textAlign: "center"}}>
                        <div style={{fontSize: "4em"}}>{days}</div>
                        <span>{declination(days, ["den", "dny", "dní"])}</span>
                    </div>
                    <div style={{margin: "0 1em", minWidth: "4em", textAlign: "center"}}>
                        <div style={{fontSize: "4em"}}>{hours}</div>
                        <span>{declination(hours, ["hodina", "hodiny", "hodin"])}</span>
                    </div>
                    <div style={{margin: "0 1em", minWidth: "4em", textAlign: "center"}}>
                        <div style={{fontSize: "4em"}}>{minutes}</div>
                        <span>{declination(minutes, ["minuta", "minuty", "minut"])}</span>
                    </div>
                    <div style={{margin: "0 1em", width: "4em", textAlign: "center"}}>
                        <div style={{fontSize: "4em"}}>{seconds}</div>
                        <span>{declination(seconds, ["vteřin", "vteřin", "vteřin"])}</span>
                    </div>
                </div>
            </div>
        )
    }
}

const declination = (number: number, options: string[]): string => {
    if (number === 1) {
        return options[0];
    } else if (number > 1 && number < 5) {
        return options[1];
    } else {
        return options[2]
    }
}

const headline: BannerLayer = {
    translateY: [0, 50],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
        <div className="video-container">
            <video autoPlay loop muted playsInline style={{width: '100%', height: 'auto'}}>
                <source src={process.env.PUBLIC_URL + '/assets/promo.mp4'} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

const photo: BannerLayer = {
    translateY: [0, -20],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
        <img src={process.env.PUBLIC_URL + '/assets/DSC_8128.jpg'} alt={"Laky Mari"} style={{width: "100%"}}/>
    )
}

const concerts: BannerLayer = {
    translateY: [0, 15],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
        <div style={{background: "#FFFFFFC0", padding: '2em 0', maxWidth: '600px', margin: '0 auto', color: '#333'}}>
            <h2>Koncerty</h2>

            <div style={{margin: "2em auto"}}>
                <strong>22. 6. od 18:00</strong><br/>
                <strong>Husa na provázku, Brno (Závěr sezóny)</strong>
            </div>

            <div style={{margin: "2em auto"}}>
                <strong>13. 7. od 19:30</strong><br/>
                <strong>Honkyponk cafe, Třebíč (Křest EP Zvířecí triptych)</strong>
            </div>

            <div style={{margin: "2em auto"}}>
                <strong>27. 7. od 18:00</strong><br/>
                <strong>Laky Mari Janz na Prázdninách v Telči (U lodiček)</strong>
            </div>

            <div style={{margin: "2em auto"}}>
                <strong>29. 7.</strong><br/>
                <strong>Laky Mari na Prázdninách v Telči (Kocouří scéna)</strong>
            </div>

            <div style={{margin: "2em auto"}}>
                <strong>3. 8.</strong><br/>
                <strong>Laky Mari a Laky Mari Janz v Radobytcích</strong>
            </div>

            <div style={{margin: "2em auto"}}>
                <strong>8. 8.</strong><br/>
                <strong>Music Lab, Brno</strong>
            </div>

            <div style={{margin: "2em auto"}}>
                <strong>28. 8.</strong><br/>
                <strong>Maringotka Jihlavská</strong>
            </div>

            <div style={{margin: "2em auto"}}>
                <strong>30. 8.</strong><br/>
                <strong>Živá kavárna u Muzea Třebíč</strong>
            </div>

            <div style={{margin: "2em auto"}}>
                <strong>19. 10. od 15:30</strong><br/>
                <strong>Petrkov (zpěv & harfa - JanáčkovaMoravská lidová poezie v písních)</strong>
            </div>

            <div style={{margin: "2em auto"}}>
                <strong>26. 12</strong><br/>
                <strong>Štěpánský punč, Třebíč</strong>
            </div>
        </div>
    )
}

const countdown: BannerLayer = {
    translateY: [0, 30],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
        <Countdown
            date={new Date('2024-03-16T19:00:00.896Z').getTime()}
            renderer={renderer}
        />
    )
}

const Home: React.FC = () => {
    return (
        <ParallaxProvider>
            <Menu/>
            <ParallaxBanner layers={[headline, countdown]} style={{height: '50vw'}}/>

            <Parallax speed={0}>

                <div className="section section--green">
                    <div style={{maxWidth: '1200px', margin: '0 auto'}}>
                        <h2>Novinky</h2>

                        <ul className="timeline">
                            <li>
                                <div><small>16. 3. 2024</small>

                                    <p><strong>Klip k písni Holubička.</strong></p>

                                    <iframe title="youtube"
                                            src="//www.youtube.com/embed/cfpBlLWTV0U?si=pkgHnAvsTm39TrHo?wmode=opaque"
                                            style={{width: "100%", height: "200px"}}
                                    ></iframe>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <small>28. 10. 2022</small>

                                    <p><strong>Nová píseň Rainy Road na Spotify i YouTube.</strong></p>

                                    <img src={process.env.PUBLIC_URL + '/assets/20221115_092225.webp'}
                                         style={{width: "100%"}}/>
                                </div>
                            </li>
                            <li>
                                <div><small>10. 4. 2022</small>

                                    <p><strong>Klip k písni Duje vítr.</strong></p>

                                    <iframe title="youtube" src="//www.youtube.com/embed/560z1CdL-MQ?wmode=opaque"
                                            style={{width: "100%", height: "200px"}}
                                    ></iframe>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Parallax>

            <Parallax speed={0}>
                <div className="section section--white">
                    <div style={{maxWidth: '1200px', margin: '2em auto'}}>
                        <h2>O nás</h2>

                        <p style={{maxWidth: "600px", margin: '1em auto 0', lineHeight: 1.6}}>
                            Jsme nadšení muzikanti,
                            kteří se shlíží v nejrůznějších hudebních žánrech. Naše autorská tvorba
                            se tak skládá z hudby tradiční - folklórní, ale ráda se pojí s žánry dalšími.
                            Kromě vlastní hudby umíme rozpoutat veselí s party repertoárem nabitým známými hity různých
                            žánrů,
                            dokážeme vytvořit koncertní atmosféru s harfou a zpěvem, nebo doprovodit komorní akce
                            jazzovým repertoárem.
                        </p>
                    </div>
                </div>
            </Parallax>

            <ParallaxBanner layers={[photo, concerts]} style={{height: '100vh'}}/>

            <Parallax speed={0}>
                <div className="section section--green">
                    <div style={{maxWidth: '1200px', margin: '0 auto'}}>

                        <h2>Členové</h2>

                        <div className="members">
                            <Member
                                name={"Mari Křížová"}
                                perex={"Autorka Laky Mari písní, hlavní hlas, klapky."}
                                description={"Mari pro Laky Mari píše texty, hudbu, spravuje sociální sítě a odepisuje vám na emaily." +
                                    "Je to tedy taková hlavní řídící jednotka Laky Mari - však je mikrofon právě v jejích rukách."}
                                imageSrc={process.env.PUBLIC_URL + '/assets/mari.jpg'}
                            />

                            <Member
                                name={"Lukáš Krupička"}
                                perex={"Elektrická kytara, zpěv, harfa, aranže, ozvučení."}
                                description={"Luky má na starosti technickou stránku Laky Mari. " +
                                    "Vše zapojit tam kam se to zapojit má a potom se postará o to, aby se prsty rozběhly " +
                                    "po správných strunách ve správný čas."}
                                imageSrc={process.env.PUBLIC_URL + '/assets/lukas.jpg'}
                            />

                            <Member
                                name={"Matouš Benda"}
                                perex={"Bubeník, který umí rozproudit zábavu."}
                                description={"Matouš je herec z Husy na provázku, kterého si půjčujeme pro náš autorský projekt, ale hrává s námi občas i na veselicích."}
                                imageSrc={process.env.PUBLIC_URL + '/assets/matous.jpg'}
                            />
                        </div>
                    </div>
                </div>
            </Parallax>

            <Parallax speed={0}>
                <div className="section section--white">
                    <div style={{maxWidth: '1200px', margin: '0 auto', display: "flex", flexDirection: "column"}}>
                        <h2>Nabízíme</h2>

                        <Offer
                            iconSrc={process.env.PUBLIC_URL + '/assets/pen.svg'}
                            title="Autorské písně"
                            text='"Folklórní jazz rock"
                                    Tak nazýváme styl našich autorských písní, které vznikají díky bezuzdné fantazii
                                    Mari Křížové a dostávají formu v rukách a aranžích Lukáše Krupičky.
                                    Hráváme ve složení: el. kytara, perkuse, zpěvy, synth piano'
                            //style={{ textAlign: "end"}}
                            align="right"
                        />


                        <Offer
                            iconSrc={process.env.PUBLIC_URL + '/assets/microphone.svg'}
                            title="Laky Mari band"
                            text="Máme repertoár složený z oblíbených party coverů českých i zahraničních kapel (od
                                    lidovky po rock).
                                    Hrajeme buď ve dvou (zpěvy, kytara, synth piano, perkuse), nebo se k nám přidává do
                                    třetice bubeník Matouš Benda."
                            //style={{ textAlign: "start", flexDirection: "row" }}
                            align="left"
                        />

                        <Offer
                            iconSrc={process.env.PUBLIC_URL + '/assets/harp.svg'}
                            title="Harfa & zpěv"
                            text="Možnost koncertního vystoupení s repertoárem z Moravské lidové poezie v písních od
                                    Leoše Janáčka a dalších lidových písní v podání harfy a zpěvu."
                            align="right"
                            //style={{ textAlign: "end" }}
                        />

                       <Offer
                           iconSrc={process.env.PUBLIC_URL + '/assets/guitar.svg'}
                           title="Laky Mari Janz"
                           text="Jazzové standardy v podání Jana Páchy (el.kytara), Mari Křížové (zpěv) a baskytary (Lukáš Krupička)"
                           align="left"
                           //style={{ textAlign: "start", flexDirection: "row" }}
                       />
                    </div>
                </div>

                <div className="section section--green">
                    <div style={{maxWidth: '1200px', margin: '0 auto', display: "flex", flexDirection: "column"}}>
                        <h2>Reference</h2>

                        <div className="references">
                            <Reference
                                text="Velcí sympaťáci a úžasní muzikanti. Co víc si přát?!"
                                author="Jana Petrů"/>

                            <Reference
                                text="Laky Mari Vás rozhodně nezklame Muzika napěchovaná
                                        emocemi, citem, originálními
                                        rytmy, širokým záběrem a fajnovou náladou Jednoznačně doporučuji."
                                author="Magdalena Křenková"/>

                            <Reference
                                text="Báječně doprovodili naši zábavu na svatbě."
                                author="Kristýna Šutková"/>
                        </div>
                    </div>
                </div>

                <div className="section section--white">
                    <div className="footer">
                        <div className="footer__booking">
                            <h2>Laky Mari booking</h2>

                            <p>+420 735 832 022<br/>
                                marikrizovamusic@gmail.com</p>
                            <p>Třebíč, 674 01</p>
                        </div>

                        <div className="footer__social">
                            <a href="https://www.facebook.com/LAKYMARIband" className="social-media-anchor">
                                <FontAwesomeIcon icon={faSquareFacebook} fontSize={56} style={{marginLeft: ".6em"}}/>
                            </a>
                            <a href="https://www.instagram.com/lakymari/" className="social-media-anchor">
                                <FontAwesomeIcon icon={faSquareInstagram} fontSize={56} style={{marginLeft: ".6em"}}/>
                            </a>
                            <a href="https://bandzone.cz/_117178" className="social-media-anchor">
                                <FontAwesomeIcon icon={faSquareYoutube} fontSize={56} style={{marginLeft: ".6em"}}/>
                            </a>
                            <a href="https://www.youtube.com/channel/UCNcKPv7IPl5emsFK_iNRCxw"
                               className="social-media-anchor">
                                <div style={{marginLeft: "1.2em"}}>Bandzone</div>
                            </a>
                        </div>
                    </div>
                </div>
            </Parallax>
        </ParallaxProvider>
    )
}

const About: React.FC = () => {
    return (
        <div>
            <h2>O nás</h2>
            {/* Obsah stránky About */}
        </div>
    )
}

const Concerts: React.FC = () => {
    return (
        <div>
            <h2>Koncerty</h2>
            {/* Obsah stránky Contact */}
        </div>
    )
}

const Members: React.FC = () => {
    return (
        <div>
            <h2>Pro pořadatele</h2>
            Naše nabídka
            ♪ Autorský koncert Laky Mari
            (Folklórní jazz rock v podání synt. piana, el. kytary, bicích a dvou hlasů)

            ♪ Harfa & zpěv - Janáčkova Moravská lidová poezie v písních & další

            ♪ Živé vystoupení na vaší oslavě - Laky Mari band
            (rock/pop/jazz/swing/funk/lidovka)

            ♪ Jazzové standardy - Laky Mari Janz
            (Ve spojení s výborným jazzovým kytaristou Janem Páchou tvoříme také jazzové uskupení. Luky - baskytara,
            Mari - zpěv, Jan - kytara)
        </div>
    )
}

const Contact: React.FC = () => {
    return (
        <div>
            <h2>Kontakt</h2>
            {/* Obsah stránky Contact */}
        </div>
    )
}

const App: React.FC = () => {
    return (
        <Router>
            <div className="App">
                <main>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/o-nas" element={<About/>}/>
                        <Route path="/koncerty" element={<Concerts/>}/>
                        <Route path="/pro-poradatele" element={<Members/>}/>
                        <Route path="/kontakt" element={<Contact/>}/>
                    </Routes>
                </main>
            </div>
        </Router>
    );
};

export default App;
